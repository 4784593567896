import {formatCurrency} from '../formatCurrency';
import {Item} from '../items/item.class';

export class TemplateHelpers {
    /**
     * Returns all TemplateHelpers
     */
    public static get allHelpers() {
        return {
            formatAddress: TemplateHelpers.formatAddress,
            formatCurrency: TemplateHelpers.formatCurrency,
            formatDate: TemplateHelpers.formatDate,
            formatPercent: TemplateHelpers.formatPercent,
            formatPhone: TemplateHelpers.formatPhone,
            prettyItem: Item.prettyItem,
            truncate: TemplateHelpers.truncate,
            uglyItem: Item.uglyItem,
        };
    }

    /**
     * Format an Address
     * @param name
     * @param attn
     * @param title
     * @param addr1
     * @param addr2
     * @param city
     * @param st
     * @param zip
     * @param ctry
     */
    public static formatAddress(
        name: string,
        attn: string,
        title: string,
        addr1: string,
        addr2: string,
        city: string,
        st: string,
        zip: string,
        ctry: string,
    ): string {
        let formattedAddress = ``;
        let newlineString = '';
        // If name is undefined, return null
        if (typeof name === `undefined`) {
            return null;
        }

        // Handle attn
        if (attn !== ``) {
            formattedAddress = `ATTN: ${attn}`;
            if (title) {
                formattedAddress += `/${title}`;
            }
            newlineString = `<br>`;
        }

        // Handle name
        if (name !== ``) {
            formattedAddress += `${newlineString}${name}`;
            newlineString = `<br>`;
        }

        // Handle addr1
        if (addr1 !== ``) {
            formattedAddress += `${newlineString}${addr1}`;
            newlineString = `<br>`;
        }

        // Handle addr2
        if (addr2 !== ``) {
            formattedAddress += `${newlineString}${addr2}`;
            newlineString = `<br>`;
        }

        // Handle city
        if (city !== ``) {
            formattedAddress += `${newlineString}${city}&nbsp;&nbsp;${st}&nbsp;&nbsp;${zip}`;
            newlineString = `<br>`;
        }

        // Handle ctry
        if (ctry?.toUpperCase() === `CA`) {
            formattedAddress += `${newlineString}CANADA`;
        }

        // Return formatted formattedAddress
        return formattedAddress;
    }

    /**
     * Currency formatter for jQuery (don't use for React - use DisplayCurrencyFormatted)
     * @param num
     * @param currency
     */
    public static formatCurrency(num: number = 0, currency: string = ``): string {
        return formatCurrency(num, currency);
    }

    /**
     * Formats Term date input expected "2017-11-28" Outputs "11/28/2017"
     * @param inputDate
     */
    public static formatDate(inputDate: string): string {
        // Return empty string for falsy values
        if (!inputDate) {
            return ``;
        }

        const utcDate = new Date(inputDate);
        const date = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
        if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return date.getMonth() + 1 + `/` + date.getDate() + `/` + date.getFullYear();
        }
    }

    /**
     * Format a Percentage
     * @param _number
     */
    public static formatPercent(_number: number): string {
        return `${TemplateHelpers._strip(_number * 100)}%`;
    }

    /**
     * Format Phone number
     * @param num
     */
    public static formatPhone(num: string): string {
        if (typeof num !== `string`) {
            return null;
        }
        let str = num.toString();
        let matched = str.match(/\d+\.?\d*/g);
        if (matched && matched.length === 3) {
            return `(${matched[0]}) ${matched[1]}-${matched[2]}`;
        } else if (matched && matched.length === 2) {
            return `${matched[0]}-${matched[1]}`;
        } else if (matched && matched.length === 1) {
            if (matched[0].length === 10) {
                return `(${matched[0].substring(0, 3)}) ${matched[0].substring(3, 6)}-${matched[0].substring(6)}`;
            }
            if (matched[0].length === 7) {
                return `${matched[0].substring(0, 3)}-${matched[0].substring(3)}`;
            }
        }
        return num;
    }

    /**
     * Returns formatted time from a UTC date string
     */
    public static getTime(inputDate: string): string {
        // Return empty string for falsy values
        if (!inputDate) {
            return ``;
        }

        // Convert to UTC date and return formatted time
        const utcDate = new Date(inputDate);
        return utcDate.toLocaleTimeString();
    }

    /**
     * Clean filterValue for regex
     * @param filterValue
     */
    public static regexSafeFilterVal(filterValue): string {
        if (filterValue && filterValue !== ``) {
            return filterValue.replace(/([-()\[\]{}+?*.$^|,:#<!\\])/g, `\\$1`).replace(/\x08/g, `\\x08`);
        }
        return filterValue;
    }

    /**
     * Add truncation tooltip if needed
     * @param text
     * @param length
     */
    public static truncate(text, length) {
        if (text.length > length) {
            return `<span data-toggle="tooltip" title="${text}">${text.substring(0, length)} ...</span>`;
        } else {
            return text;
        }
    }

    /**
     * Strip number to 3 digit precision
     * @param _number
     * @private
     */
    private static _strip(_number) {
        return parseFloat(_number).toPrecision(3);
    }
}
