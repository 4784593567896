import * as React from 'react';
import {Variant} from 'react-bootstrap/types';

import {Alerts} from '../../ui/Alerts/Alerts';
import {CardItem} from '../item.class';

export interface ItemCardAlert {
    itemNum: string;
    message: string;
    type: Variant;
}

interface ItemCardAlertsProps {
    cardItem: CardItem;
    itemCardAlert: ItemCardAlert;
    nextShipment: string;
    onReplace: (oldItemNum: string, quantity: number, categoryPath: string, shipmentDate: string) => void;
    orderQty: number;
    stepperError: {error: string; message: string};
}

export const ItemCardAlerts = ({cardItem, itemCardAlert, nextShipment, onReplace, orderQty, stepperError}: ItemCardAlertsProps) => (
    <div className="tw-relative tw-p-0">
        {cardItem.discontinued && (
            <Alerts
                alertLinkHidden={!onReplace}
                alertLinkHref="#"
                alertLinkText="Replace Item"
                className="tw-mx-4 tw-mt-4 tw-mb-0"
                message="Discontinued Item"
                onClickAlertLink={(e) => {
                    e.preventDefault();
                    onReplace(cardItem.item || cardItem.id, orderQty, cardItem.categoryPath, nextShipment);
                }}
                variant="warning"
            />
        )}
        {itemCardAlert && (itemCardAlert.itemNum === cardItem.item || itemCardAlert.itemNum === cardItem.id) && (
            <Alerts
                className="tw-mx-4 tw-mt-4 tw-mb-0"
                message={itemCardAlert.message}
                variant={itemCardAlert.type}
            />
        )}
        {cardItem.replaced && (
            <Alerts
                className="tw-mx-4 tw-mt-4 tw-mb-0"
                message="Item has been replaced."
                variant="success"
            />
        )}
        {stepperError?.error && (
            <Alerts
                className="tw-mx-4 tw-mt-4 tw-mb-0"
                message={stepperError.message}
                variant="danger"
            />
        )}
    </div>
);
